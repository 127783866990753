import React, { useState, useEffect } from 'react';
import { Stepper, Step} from '@mui/material';
import '../index.css'
import '../assets/main'
import { handleOptionClick } from './logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    components: {
      MuiStepper: {
        styleOverrides: {
          root: {
            display: 'block', 
            WebkitBox: 'initial', 
            WebkitFlex: 'initial',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
});

/* inicio steps */
const steps = [ 
    /* step 1 */ 
    { content: <div className="wizard-forms">
    <div className="inner clearfix p-8 z-10 sticky sm:p-2">
        <div className="form-content pera-content w-full">
            <div className="pb-[50%]"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '0%"> '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac text-base sm:text-sm 2xl:text-lg">A empresa realizou uma análise de mercado ou durante a crição da solução?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm"> Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p1_ul">
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_1" data-value='7'>Sim</li>
                        <li className='sm:text-sm 2xl:text-lg md:text-base' id="p1_op_2" data-value='0'>Não</li>                        
                    </ul>
                </div>
            </div> 
            {/* <label id="p1_label" className="required" style={{color: 'red '}}>Esta informação é obrigatória. Por favor selecione uma opção</label> */}
        </div>
    </div>
</div> }, /* step 2 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '3.3% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">O mercado ao qual a solução está inserida apresenta dados reais de crescimento no longo prazo?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p2_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p2_op_4" data-value='7'>Atende 100%</li>                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> }, /* step 4 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute"> 
            {/* <span>2 de 38 Completo</span>
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '6.6% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A solução está alinhada a pelo menos uma tendência de mercado?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p3_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p3_op_4" data-value='7'>Atende 100%</li>                          
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 5*/ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '9.9% '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existem startups e empresas emergentes atuando nesse mercado?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p4_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p4_op_4" data-value='7'>Atende 100%</li>            
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 6 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '13.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existem outros players, fundos de investimentos e grandes empresas investindo no mesmo mercado no qual a solução está inserida?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p5_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p5_op_4" data-value='7'>Atende 100%</li>                         
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 7 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '16.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Mercado</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa ou produto conhece o tamanho do mercado em que está inserido? (Considere a metodologia TAM, SAM, SOM ou similares)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p6_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p6_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 8 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '19.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Em que estágio de desenvolvimento o produto/serviço se encontra?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p7_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_1" data-value='1'>Ideia</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_2" data-value='3'>protótipo</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_3" data-value='5'>MVP</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p7_op_4" data-value='7'>Sistema Completo</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 9 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '23.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">O produto/serviço é suportado através de equipe de tecnologia (interna ou parceiro)?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p8_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p8_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 10 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '26.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A organização possui um processo de roadmapping tecnológico para o produto/serviço?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p9_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p9_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 11 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '29.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A arquitetura tecnológica do produto/serviço permite escalabilidade e desempenho adequados?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p10_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p10_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 12 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '33.3 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existem métricas e análises para monitorar o desempenho e uso do produto/serviço?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p11_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p11_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 13 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '36.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Solução e Estrutura</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A solução de tecnologia é documentada? (Ex: especificações técnicas, fluxogramas,  manuais, Docs para Clientes e etc...)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p12_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p12_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 14 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '39.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Estratégia/ Modelo de negócios</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A solução possui uma dor ou necessidade bem delimitada e validada com usuários reais?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p13_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p13_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div> 
                {/* <div className="comment-box">
                    <p>Justificativa</p>
                    <textarea name="p13_justification"></textarea>
                </div> */}
            </div>
        </div>
    </div>
</div> },
 /* div 15 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '43.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Estratégia/ Modelo de negócios</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A proposta de valor da solução está clara e é validada pelos usuários/consumidores?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p14_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p14_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 16 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '46.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Estratégia/ Modelo de negócios</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Os usuários/consumidores demonstram interesse em pagar pela solução?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p15_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p15_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 17 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '49.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Estratégia/ Modelo de negócios</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A solução se demonstra financeiramente viável ao longo do tempo? (Considere viável sendo receitas maiores que custos e despesas)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p16_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p16_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 18 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '53.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Performance</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Possui o volume de vendas dentro do planejado.</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p17_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p17_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 19 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '56.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Performance</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Temos uma queda no número de clientes controlada nos últimos 6 meses. (Ex: taxa de cancelamento de compra ou número de recompra em queda)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p18_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p18_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 20 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '59.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Performance</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">A empresa ou produto tem clareza no seu processo de vendas. (Funil de Vendas, captação de leads, uso de CRM, integrações entre sistema de marketing e vendas.)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p19_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p19_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 21 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '63.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Performance</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Como está o resultado do Life Time Value (LTV) sobre Custo por Aquisição de Cliente (CAC)?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p20_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_1" data-value='0'>Não sei responder</li>  
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_2" data-value='1'>CAC/LTV abaixo de 1</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_3" data-value='5'>CAC/LTV entre 1 e 2</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p20_op_4" data-value='7'>CAC/LTV maior/igual a 3</li>                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '66.6 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Performance</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Existe estratégia de retenção com os clientes atuais? (Ex: canais de atendimento, estratégias focadas na experiência do cliente, pós-venda etc)</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p21_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p21_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 23 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '69.9 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Time</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">O time envolvido nesta solução/produto tem papéis e responsabilidades bem definidas?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p22_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p22_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 24 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '73.2 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Time</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Temos KPIs para 100% do time?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p23_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p23_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> },
 /* div 25 */ 
{content: 
<div className="wizard-forms">
    <div className="inner clearfix p-8 sm:p-2 z-10 sticky">
        <div className="form-content pera-content w-full">
            <div className="step-inner-content"> 
            {/*<div id="placeholder" className='w-[28%] float-right'>
            </div>*/}
            {/* <span className="step-no bottom-line">Step 1</span> */} {/* <div className="step-progress float-right w-3/12 absolute">
                    <div className="step-progress-bar">
                        <div className="progress p-1">
                            <div className="progress-bar" style={{width: '76.5 '}}></div>
                        </div>
                    </div>
                </div> */} 
                <div className="space-y-4">
                    <h2 className='text-4xl sm:text-3xl 2xl:text-5xl'>Time</h2>
                    <p className="font-medium text-black-opac sm:text-sm text-base 2xl:text-lg">Temos metas para 100% do time?</p>
                </div>
                <div className="services-select-option">
                    <div>
                        <span className="text-black-opac sm:text-sm"> Selecione a opção mais adequada.</span>
                        <span className="text-opaque sm:text-sm">Resposta obrigatória*</span>
                    </div>
                    <ul style={{listStyleType: 'none '}} id="p24_ul">
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_1" data-value='0'>Não se aplica</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_2" data-value='1'>Não atende</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_3" data-value='4'>Atende parcialmente</li>
                        <li className='sm:text-sm md:text-base 2xl:text-lg'  id="p24_op_4" data-value='7'>Atende 100%</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> }
]
/* fim steps */

function Wizard({
  updateFormValues,
  onBackClick,
  logSubmit,
  updateWizardData,
  data,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipSteps, setSkipSteps] = useState(false);
  const [wizardData, setWizardData] = useState([{'p1':'1','value':'0'},{'p2':'1','value':'0'},
  {'p3':'1','value':'0'},{'p4':'1','value':'0'},{'p6':'1','value':'0'},{'p6':'1','value':'0'}]);

  useEffect(() => {
    const optionClickHandler = (e) => {
      const clickedOption = e.target.closest(".services-select-option li");
      if (clickedOption) {
        const newData = handleOptionClick(e, data)
        updateWizardData(newData);

        console.log("Updated data:", newData);

        const options = document.querySelectorAll(".services-select-option li");
        options.forEach((option) => option.classList.remove("active"));
        clickedOption.classList.add("active");
        
        updateFormValues(newData);       
      }
    };

    document.addEventListener("click", optionClickHandler);

    return () => {
      document.removeEventListener("click", optionClickHandler);
    };
  }, [updateFormValues]);

  const handleNext = () => {
    const activeOptions = document.querySelectorAll(".services-select-option li.active");
    
    if (activeOptions.length === 0) {
      const options = document.querySelectorAll(".services-select-option li");
      options.forEach((option) => {
        option.style.border = "1px solid red";
      });
      return; // Não vai para o próximo passo
    }

    if (activeStep === 0) {
        const selectedOption = activeOptions[0].id;
        if (selectedOption === "p1_op_2") {
          setSkipSteps(true);
          setActiveStep(6); // Pula para o passo valido          
          console.log("step ativo:", activeStep);
          return;
        }
     }

    if (activeStep === steps.length - 1) {
        logSubmit(data); // Chama logSubmit quando estiver no último passo
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (skipSteps && activeStep === 7) {
      setActiveStep(0); // Retorna para o passo 0
      setSkipSteps(false); // Reseta o state
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      if (activeStep === 0) {
        setSkipSteps(false); // Reseta o state se o user estiver voltando para o primeiro passo
      }
    }
  };

  return (
    <div className="w-full lg:flex-1 2xl:flex 2xl:flex-col 2xl:items-center h-full z-10">
      <ThemeProvider theme={theme}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <div
                className={`w-full ${
                  activeStep === index
                    ? "animate__animated animate__fadeInLeft"
                    : ""
                }`}
              >
                {activeStep === index && (
                  <div className="w-full">{step.content}</div>
                )}
              </div>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
      <div className="flex sticky bottom-0 z-[100] p-5 md:p-4 space-x-5 bg-white-greyish action-div justify-center w-full sm:hidden">
        <button
          onClick={activeStep === 0 ? onBackClick : handleBack}
          className="bg-black text-white flex p-5 md:p-4 lg:p-4 lg:w-5/12 2xl:w-4/12 w-4/12 2xl:text-lg justify-around items-center text-sm transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400"
          type="button"
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ color: "#ffffff" }}
            size="lg"
          />
          Voltar para o inicio
        </button>
        <button
        id="next"
          onClick={handleNext}
          type='button' className="js-btn-next bg-primary text-white w-6/12 lg:w-8/12 2xl:w-4/12 2xl:text-lg p-5 md:p-4 lg:p-4 flex justify-around transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400"
        >
          {activeStep === steps.length - 1
            ? "Enviar respostas"
            : "Avançar para a próxima etapa"}
          {activeStep !== steps.length - 1 && (
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ color: "#ffffff" }}
              size="lg"
            />
          )}
        </button>
      </div>
      <div className="flex flex-col justify-center items-center w-full md:hidden lg:hidden xl:hidden 2xl:hidden p-2">
      <button
        id="next"
          onClick={handleNext}
          className="js-btn-next bg-primary text-white w-full p-4 flex justify-around items-center hover:text-white focus:text-white"
          type="button"
        >
          {activeStep === steps.length - 1
            ? "Enviar respostas"
            : "Avançar para a próxima etapa"}

        </button>
        <button
          onClick={activeStep === 0 ? onBackClick : handleBack}
          className="text-black-opac p-5 flex w-full items-center justify-center font-semibold text-center"
          type="button"
        >
          Voltar
        </button>
      </div>
      {/*
        <div>
          {activeStep + 1} de {steps.length}
        </div>
        */}
    </div>
  );
}

export default Wizard;
