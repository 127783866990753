import Feedback from "./feedback";

export const handleOptionClick = (e, data) => {
  const [p, op, value] = e.target.id.split('_');

  const dataValue = e.target.getAttribute("data-value");
  
  // Atualizar estilos
  document.getElementById(`${e.target.id}`).style.borderColor = "#622288";
  //const optionIds = [1, 2, 3, 4, 5, 6];
  const optionIds = [1, 2, 3, 4];
  optionIds.forEach((optionId) => {
    if (e.target.id !== `${p}_op_${optionId}`) {
      var element = document.getElementById(`${p}_op_${optionId}`);
      if( element ){
        element.style.borderColor = "#ededed";
      }        
    }
  });
  
  let updatedData = [...data];
  
  /*if (p === 'p1' && op === 'op' && value === '2') {
    // Adicionar os próximos 6 p's com op_1 em updatedData
    for (let i = 2; i <= 7; i++) {
      updatedData.splice(i-2, 0, { [`p${i}`]: '1', 'value': '7' });
    }
  }

  // Verificar se p1 era op_2 e agora é op_1
  if (p === 'p1' && op === 'op' && value === '1') {
    // Remover os dados dos 6 p's adicionados anteriormente
    for (let i = 2; i <= 7; i++) {
      const indexToRemove = updatedData.findIndex(item => Object.keys(item)[0] === `p${i}_op_1`);
      if (indexToRemove !== -1) {
        updatedData.splice(indexToRemove, 1);
      }
    }
  } */
  // Atualizar dados
  let isExists = -1;
  for (let i = 0; i < updatedData.length; i++) {
    if (updatedData[i].hasOwnProperty(p)) {
      isExists = i;
      break;
    }
  }
  if (isExists >= 0) {
    updatedData[isExists] = { [p]: value };
    updatedData[isExists]['value'] = dataValue;

  } else {
    updatedData.push({ [p]: value, 'value':dataValue });
  }

  console.log("dados atualizados:", updatedData);
  // Atualizar classe ativa
  const options = document.querySelectorAll(".services-select-option li");
  options.forEach(option => option.classList.remove("active"));
  e.target.classList.add("active");

  const anyActive = Array.from(options).some(option => option.classList.contains("active"));

  // Desabilitar o botão "next" se nenhum elemento estiver ativo
  const nextButton = document.getElementById("next");
  if (!anyActive) {
    nextButton.disabled = true;
  } else {
    nextButton.disabled = false;
  }
  
  //console.log('ativo')
  return updatedData;
};


