import React from 'react';
import 'tailwindcss/tailwind.css';
import '../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';

function Terms({ onBackClick })  {
    return (
        <div className='w-7/12 lg:w-[51%] md:w-full sm:w-full 2xl:p-8 flex-col justify-between space-y-6 p-4 h-screen flex'>
            <h1 className='text-black text-5xl sm:text-3xl md:text-3xl'>
                <strong>Termos de uso e de compartilhamento de dados!</strong>
            </h1>
            <p className='text-black-opac sm:text-sm 2xl:text-lg'>
                <strong>Termo de Aceite para Recolhimento de Dados e Uso do Aplicativo do Cliente</strong>
                <br />
                Este Termo de Aceiteestabelece as condições para a coleta, tratamento e uso dos dados fornecidos pelo usuário, bem como as condições de uso do aplicativo fornecido pela empresa [nome da empresa], registrado sob o número [número de registro].
                Ao aceitar este Termo de Aceite, você, usuário do aplicativo, declara estar ciente e de acordo com as condições aqui estabelecidas.
            </p>
        {/* termos */}
        <div className='overflow-auto w-full'>
            <ol className='list-decimal pl-8 sm:text-xs 2xl:text-lg'>
                <li >
                    <strong>Coleta e Tratamento de Dados</strong>
                    <ol className='list-alpha pl-8'>
                        <li>
                            Ao utilizar o aplicativo, você concorda em fornecer informações como nome completo, endereço, telefone e e-mail, bem como quaisquer outras informações que julgar relevantes para avaliar a empresa.                        
                        </li>
                        <li>
                            A empresa ACE Cortex compromete-se a utilizar tais informações apenas para os fins descritos neste Termo de Aceite, mantendo a confidencialidade e a privacidade dos dados fornecidos.                        
                        </li>
                        <li>
                        A empresa ACE Cortex poderá, ainda, coletar informações sobre o uso do aplicativo, tais como dados de navegação e interação com o aplicativo. Essas informações serão utilizadas para aprimorar a experiência do usuário e fornecer serviços mais eficientes
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Uso do sistema</strong>
                    <ol className='list-alpha pl-8'>
                        <li>
                            Ao utilizar o aplicativo, você concorda em respeitar as regras de conduta estabelecidas pela empresa [nome da empresa], abstendo-se de utilizar o aplicativo para atividades ilegais, violação de direitos autorais, ou quaisquer outras atividades que possam prejudicar a empresa ou terceiros.
                        </li>
                        <li>
                            A empresa [nome da empresa] se reserva o direito de suspender ou cancelar o acesso ao aplicativo, sem aviso prévio, caso o usuário descumpra as regras estabelecidas neste Termo de Aceite.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Avaliação da Empresa</strong>
                    <ol className='list-alpha pl-8'>
                        <li>
                            A empresa <strong>ACE Cortex</strong> utilizará as informações fornecidas pelo usuário para avaliar a empresa em questão, bem como para aprimorar seus serviços e desenvolver novas soluções para atender às necessidades dos usuários.
                        </li>
                        <li>
                            O usuário declara estar ciente de que a avaliação da empresa poderá ser compartilhada com terceiros, desde que de forma anônima e agregada, não sendo divulgadas informações que possam identificar o usuário ou a empresa avaliada.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Aceite do Termo de Aceite</strong>
                    <br />
                    Ao clicar no botão "Aceito" ou utilizar o aplicativo, o usuário declara ter lido e concordado com este Termo de Aceite.
                    O usuário declara ainda que as informações fornecidas são verdadeiras e que está autorizado a fornecê-las à empresa [nome da empresa].
                </li>
            </ol>
        </div>
        <button className="bg-black text-white p-5 flex w-4/12 lg:w-6/12 sm:w-6/12 md:w-5/12 sm:p-3 justify-around sm:justify-center 2xl:justify-center 2xl:text-lg items-center text-sm transition ease-in-out hover:text-white hover:scale-110 duration-200 focus:text-white delay-400" onClick={onBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#ffffff" }} size='lg' className='sm:mr-5 2xl:mr-10'/>
            Voltar para o início 
          </button>
        </div>
    );
}

export default Terms;
