import React, {useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import '../index.css';
import '../assets/css/theme.css';
import Feedback from './feedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import bgHomeMobile from '../images/bgHomeMobile.svg'
import Terms from './terms';
import Form from './form';

function App() {
  const [showInitial, setShowInitial] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [showForm, setShowForm] = useState(false); 
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [agreement, setAgreement] = useState(false);


  const showFeedback = () => {
    setIsFeedbackVisible(true);
  }

  const handleLinkClick = () => {
    setShowInitial(false);
    setShowTerms(true);
  };
  
  const handleBackClick = () => {
    setShowInitial(true);
    setShowTerms(false);
  };

  const handleFormButtonClick = () => {
    if (!agreement) {
      alert("Por favor, aceite os termos.");
      console.log("O valor de agreement é:", agreement);
      return; // Saia da função se o acordo não for aceito
    }
  
    setShowInitial(false);
    setShowForm(true);
    console.log("O valor de agreement é:", agreement);
  };
  

  return (
    <div className='flex flex-row md:flex-col sm:flex-col h-screen bg-white-greyish w-screen sm:overflow-x-hidden' >
       {isFeedbackVisible ? (
        <div id="thank-you" className="thank-you-section justify-center flex items-center h-full  w-full bg-white-greyish">
  
            <Feedback />

          {/* <div className="lg:hidden xl:hidden md:hidden 2xl:hidden justify-center flex items-center w-full bg-white-greyish">
            <FeedbackMobile />
       </div> */}
        </div>
      ) : (
      <div  id='wizard_form' style={{display: "flex"}} className='w-full z-0 sm:flex-col md:flex-col'> 

      <div className='h-full sm:hidden md:hidden div-com-imagem w-5/12 2xl:w-4/12'>
      </div> 


      <div className='h-full md:h-auto sm:h-auto xl:hidden 2xl:hidden lg:hidden w-full'>
        <img src={bgHomeMobile} alt="image bg" className='h-full w-full sm:h-auto' />
      </div> 
      
      {/* div com texto principal */}
      {showInitial ? (
        <div id="initial" className='w-7/12 lg:flex-1 flex-col space-y-6 justify-evenly 2xl:justify-evenly p-10 lg:p-8 md:p-6 md:w-full sm:w-full sm:justify-normal sm:space-y-14 sm:p-3 md:justify-evenly h-screen flex'>
          <h1 className='text-black text-5xl lg:text-4xl md:text-4xl 2xl:text-5xl sm:text-4xl sm:mt-3'>Boas-vindas ao ACE <br className='md:hidden sm:hidden'/> innovation rating! </h1>
          <p className='text-black-opac text-xl sm:text-base lg:text-lg 2xl:text-2xl 2xl:text-left'>
            O objetivo é ajudá-lo a entender os pontos fortes e fracos do seu negócio, identificar oportunidades de crescimento e desenvolvimento,
            e entender o que precisa ser o foco dos próximos passos.
          </p>

          {/* Como funciona */}
          <div className='bg-grey w-full p-4 flex flex-row justify-between rounded-lg md:text-lg md:p-2 lg:text-sm  2xl:text-xl 2xl:p-6 2xl:w-8/12 sm:text-sm sm:p-2 sm:w-11/12 sm:self-center'>
            <div className='flex flex-col p-2 sm:p-4 space-y-2'>
              <p className='text-black font-semibold lg:text-lg '> Como funciona?</p>
              <p className='sm:text-sm'>
                No processo você irá responder <b> 24 perguntas</b>.
                O questionário leva em torno de <b>5 minutos</b> para ser respondido.
              </p>
            </div>
          </div>

          {/* Links para os termos */}
          <div className='flex flex-row justify-between 2xl:justify-normal w-full items-center'>
          <div className='checkbox-wrapper-1 flex flex-row'>
            <input type="checkbox" id="example-1" class="substituted cursor-pointer"       
              onChange={() => setAgreement(true)} />
            <label htmlFor="example-1"  onClick={() => setAgreement(true)} className="mt-2"> 
            </label>
            <p className='pl-2 lg:text-sm md:text-lg 2xl:text-xl 2xl:text-left sm:text-xs'>Para começar, declaro que aceito os <button className="text-primary" onClick={handleLinkClick}>  termos de uso</button> e os <button className="text-primary" onClick={handleLinkClick}> termos de recolhimento de dados</button> e que estou de acordo a compartilhar meus dados com a ACE Cortex.
            </p>
          </div>
          </div>

          <button className="bg-primary text-white p-5 flex w-8/12 sm:text-base sm:w-full sm:p-4 lg:w-10/12 md:w-10/12 lg:p-3 md:p-4 md:text-lg 2xl:text-xl 2xl:w-6/12 2xl:p-6 justify-between items-center transition ease-in-out hover:text-white hover:scale-105 duration-200 focus:text-white delay-400" onClick={handleFormButtonClick} >
            <p className='sm:hidden'>Realizar o diagnóstico da minha empresa</p>
            <p className='lg:hidden md:hidden xl:hidden 2xl:hidden sm:text-sm'>Diagnosticar minha empresa</p>
            <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} className='lg:h-5 2xl:h-7 md:h-6'/>
          </button>
        </div> 
      ) : null }
         {showTerms && <Terms onBackClick={handleBackClick} />}
         {showForm && <Form showFeedback={showFeedback} /> }
    </div>
    )} 
    </div>
  );
}

export default App;
