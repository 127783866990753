import React, { useState,useEffect } from 'react';
import "../index.css";
import "../assets/main";
import 'tailwindcss/tailwind.css';
import bgFeedBack from "../images/imgfeedback.png";
import bgMobileFeedback from "../images/headerMobile.svg"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

 
const theme = createTheme({
    components: {
      MuiStepper: {
        styleOverrides: {
          root: {
            display: 'block', 
            WebkitBox: 'initial', 
            WebkitFlex: 'initial',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
});

function Feedback() {
  
	useEffect(() => {
		const rootDiv = document.getElementById('root');
		const feedbackDiv = document.getElementById('feedback');
	
		const observer = new IntersectionObserver(entries => {
		  entries.forEach(entry => {
			if (entry.isIntersecting) {
			  rootDiv.classList.add('mb-body');
			} else {
			  rootDiv.classList.remove('mb-body');
			}
		  });
		});
	
		observer.observe(feedbackDiv);
	
		return () => {
		  observer.disconnect();
		};
	  }, [])

	  const scrollToSection = () => {
		const element = document.getElementById("target");
		if (element) {
		  element.scrollIntoView({ behavior: "smooth" });
		}
	}

  return (
    <div className="w-full 2xl:w-10/12 flex flex-col items-center lg:space-y-8 lg:pt-0 xl:space-y-8 xl:pt-0 2xl:space-y-8 2xl:pt-0 md:space-y-8 md:pt-0  bg-white-greyish h-screen sm:overflow-x-hidden" id="feedback">
      <div className="w-full 2xl:w-10/12 xl:w-10/12 flex sm:hidden relative">
      <div className="overflow-hidden flex 2xl:mt-10 items-start w-full">
        <img src={bgFeedBack} className=" w-full align-top"/>
		</div>
      </div>

	  <div className=" w-full flex items-start md:hidden lg:hidden xl:hidden 2xl:hidden relative ">
        <img src={bgMobileFeedback} className=" w-full align-top"/>
		</div>
		
		{/* Elemento div para tablets e telas de desktops */}
      <div id="target" className="w-full 2xl:w-10/12 xl:w-10/12 p-12 max-h-80 flex flex-col sm:p-5 items-center sm:mt-7 space-y-4 bg-white-greyish mb-30 pb-[30rem]">
		<ThemeProvider theme={theme}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<div className="w-full p-2 sm:p-1 flex flex-row justify-between items-center">
					<span className="text-2xl sm:text-3xl 2xl:text-4xl xl:text-3xl font-semibold text-white cormorant-font ml-4" id="result">Diagnostico</span>
					<div className="flex flex-row w-auto p-2 rounded-[63px] bg-white-greyish items-center">
						<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl font-semibold" id="grade-result"></p>
						<p className="sm:text-lg md:text-lg 2xl:text-2xl xl:text-2xl text-xl text-black-opac"> /10</p>
					</div> 
				</div>
            </AccordionSummary>
            <AccordionDetails>
			<div className="text-black-opac mt-4">
				<p className="bg-[#D9D9D9] p-4 sm:p-2 sm:text-xs rounded-lg" id="result-comment"></p>
			</div>
			</AccordionDetails>
          </Accordion>
		  </ThemeProvider>
      	</div>
</div>
  );
}

export default Feedback;
