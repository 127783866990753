$(function() {

    // Select Dropdown
    $('html').on('click', function() {
        $('.select .dropdown').hide();
    });
    $('.select').on('click', function(event) {
        event.stopPropagation();
    });
    $('.select .select-control').on('click', function() {
        $(this).parent().next().toggle();
    })
    $('.select .dropdown li').on('click', function() {
        $(this).parent().toggle();
        var text = $(this).attr('rel');
        $(this).parent().prev().find('div').text(text);
    })

    // date picker
    $('.datepicker').datepicker({
        clearBtn: true,
        format: "dd/mm/yyyy"
    });


    $(".step-box-content ").on('click', function() {

        $(".step-box-content").removeClass("active");
        $(this).addClass("active");
    });

    // $(".services-select-option li").on('click', function() {

    //     $(".services-select-option li").removeClass("active");
    //     $(this).addClass("active");
    // });

    $(".opti-list ul li").on('click', function(e) {
        $(this).find('input[type=checkbox]').prop("checked", !$(this).find('input[type=checkbox]').prop("checked"));

        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this).addClass("active");
        }
    });

    $('input[type=checkbox]').click(function(e) {
        e.stopPropagation();
        return true;
    });

    $(".plan-icon-text").on('click', function() {
        $(this).find('input[type=radio]').prop("checked", true);
        $(".plan-icon-text").removeClass("active");
        $(this).addClass("active");
    });
})